import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Footer,
  SplitSectionImage,
  Button,
  StepsBlock,
  Step,
} from '../components';
import MobileGraphic from '../assets/images/graphics/swap-mobile.png';
import SwapFeesGraphic from '../assets/images/graphics/swap-fees.png';
import AxianceMobile from '../assets/images/graphics/axiance-mobile.png';
import BackgroundImage from '../assets/images/backgrounds/swap-fees-header-bg.png';
import MobileBackgroundImage from '../assets/images/backgrounds/swap-fees-header-m-bg.png';
import Icons from '../assets/images/icons';
import RegistrationLinks from '../utils/RegistrationLinks';
import '../styles/pages/SwapFees.scss';

function SwapFees({ entity }) {
  const { i18n, t } = useTranslation();
  const { language } = useParams();
  const steps = t('swapFees.simpleSteps.steps', { returnObjects: true });
  const termsUrl = 'https://prismic-io.s3.amazonaws.com/axiance/628ad5e7-2988-4110-8670-c29d1daca948_Black+Friday+Promotion_terms_and_conditions_Nov2021.pdf';

  useEffect(() => {
    if (language) i18n.changeLanguage(language);
    const script = document.createElement('script');
    script.src = 'https://axianceteam.slack.com//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="swap-fees">
      <Header
        cssClass="header"
        image={MobileGraphic}
        background={BackgroundImage}
        mobileBackground={MobileBackgroundImage}
        scroller
      >
        <h1 className="title">{t('swapFees.header.title')}</h1>
        <h2 className="subtitle">{t('swapFees.header.subtitle')}</h2>
        <h3 className="text">{t('swapFees.header.text')}</h3>
        <a href={termsUrl} target="_blank" rel="noreferrer" className="terms">{t('terms')}</a>
        <div className="cta-box">
          <Button
            secondaryColor
            riskWarning
            link={RegistrationLinks.intAffise.concat('1')}
            text={t('cta.join')}
            arrow
          />
        </div>
      </Header>
      <SplitSectionImage
        cssClass="zero-swap"
        reversed
        image={{ src: SwapFeesGraphic, alt: 'Zero Swap Fees' }}
      >
        <p className="top">{t('swapFees.swapFees.top')}</p>
        <h1 className="title">{t('swapFees.swapFees.title')}</h1>
        <p className="text" dangerouslySetInnerHTML={{ __html: t('swapFees.swapFees.text', { interpolation: { escapeValue: false } }) }} />

        <a href={termsUrl} target="_blank" rel="noreferrer" className="terms">{t('terms')}</a>
        <div className="cta-box">
          <Button
            secondaryColor
            riskWarning
            link={RegistrationLinks.intAffise.concat('2')}
            text={t('cta.create')}
            arrow
          />
        </div>
      </SplitSectionImage>
      <StepsBlock
        title={t('swapFees.simpleSteps.title')}
        cta
        link={RegistrationLinks.intAffise.concat('3')}
      >
        {steps.map((step, stepCount) => (
          <Step
            icon={Icons[step.icon]}
            digit={step.digit}
            title={step.title}
            text={step.text}
            splitter={stepCount < steps.length - 1}
          />
        ))}
      </StepsBlock>
      <SplitSectionImage
        cssClass="existing"
        reversed
        image={{ src: AxianceMobile, alt: 'Mobile interface' }}
      >
        <h1 className="title">{t('swapFees.existing.title')}</h1>
        <p className="text" dangerouslySetInnerHTML={{ __html: t('swapFees.existing.text', { interpolation: { escapeValue: false } }) }} />
        <div className="cta-box">
          <Button
            secondaryColor
            riskWarning
            link={RegistrationLinks.intAffise.concat('4')}
            text={t('cta.create')}
            arrow
          />
        </div>
      </SplitSectionImage>
      <div className="credit-box">
        <div className="credit-box-inner container">
          <h1 className="title">{t('swapFees.credit.title')}</h1>
          <p className="text" dangerouslySetInnerHTML={{ __html: t('swapFees.credit.text', { interpolation: { escapeValue: false } }) }} />
          <div className="cta-box">
            <Button
              secondaryColor
              riskWarning
              link={RegistrationLinks.intAffise.concat('5')}
              text={t('cta.signIn')}
              arrow
            />
          </div>
        </div>
      </div>
      <Footer entity={entity} />
    </div>
  );
}

export default SwapFees;
