import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import '../styles/components/Button.scss';

export default function Button({
  secondaryColor, text, arrow, action, link, riskWarning, formButton,
}) {
  const { t } = useTranslation();
  const ctaStyle = {
    color: secondaryColor ? '#000' : '#fff',
    backgroundColor: secondaryColor ? '#D5E05B' : '#50B848',
  };

  return (
    <div className="cta-container">
      {link ? (
        <a href={link} className={`cta secondary-bg-color ${arrow ? 'arrow-cta' : null}`} style={ctaStyle}>
          {text}
          {arrow ? (
            <BsArrowRight className="arrow-icon" />
          ) : null}
        </a>
      ) : (
        <button
          className={`cta secondary-bg-color ${arrow ? 'arrow-cta' : null}`}
          style={ctaStyle}
          type={formButton ? 'submit' : 'button'}
          onClick={action}
        >
          {text}
          {arrow ? (
            <BsArrowRight className="arrow-icon" />
          ) : null}
        </button>
      )}
      {riskWarning
        ? <p className="cta-warning">{t('cta.risk')}</p>
        : null}
    </div>
  );
}
