import React from 'react';
import '../styles/components/LogoBar.scss';
import Zoom from 'react-reveal/Zoom';
import {
  Visa, Mastercard, Skrill, iSignthis, Neteller,
} from '../assets/images/logos';

export default function LogoBar() {
  return (
    <div className="logo-bar">
      <Zoom bottom cascade>
        <div className="logo-bar-inner container">
          <img src={iSignthis} className="logo" alt="iSignthis" />
          <img src={Mastercard} className="logo" alt="Mastercard" />
          <img src={Neteller} className="logo" alt="Neteller" />
          <img src={Skrill} className="logo" alt="Skrill" />
          <img src={Visa} className="logo" alt="VISA" />
        </div>
      </Zoom>
    </div>
  );
}
