import React from 'react';
import '../styles/components/ScrollDown.scss';
import { BsArrowDown } from 'react-icons/bs';
import Jump from 'react-reveal/Jump';

export default function ScrollDown() {
  const scroll = () => {
    window.scrollBy(0, document.body.clientHeight * 0.75);
  };

  return (
    <Jump bottom cascade>
      <div className="scroll-down">
        <button type="button" className="button" onClick={() => scroll()}>
          <BsArrowDown className="arrow-icon" />
        </button>
      </div>
    </Jump>
  );
}
