import React from 'react';
import '../styles/components/SplitSectionImage.scss';

export default function SplitSectionImage({
  children, cssClass, image, reversed,
}) {
  return (
    <div className={`split-section-image ${cssClass}`}>
      <div className={`split-section-image-inner container ${reversed ? 'reverse' : null}`}>
        <div className="text-box">
          {children}
        </div>
        <div className="image-box">
          <img
            alt={image.alt}
            src={image.src}
            className="image"
          />
        </div>
      </div>
    </div>
  );
}
