import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { PageLoader } from './components';
import './styles/Generic.scss';
import App from './App';
import i18n from './i18n';

ReactDOM.render(
  <Suspense fallback={<PageLoader />}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root'),
);
