import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import mt4 from '../assets/images/graphics/mt4.png';
import mt5 from '../assets/images/graphics/mt5.png';
import webtrader from '../assets/images/graphics/webtrader.png';
import '../styles/components/TabsBlock.scss';

export default function TabsBlock() {
  const [platform, setPlatform] = useState('mt4');
  const platformData = {
    mt4: {
      text: 'The Meta Trader4 (MT4) trading platform reigns supreme and is trusted by 10+ million users worldwide who enjoy a stable and reliable access to global markets.',
      image: mt4,
      bullets: [
        '20+ analytical charting tools',
        '1-click trading',
        '39 languages',
      ],
    },
    mt5: {
      text: 'Expand your investment portfolio with the award-winning Meta Trader 5 institutional multi-asset platform. Voted best multi-asset and FX trading platform of 2020 by Finance Magnates.',
      image: mt5,
      bullets: [
        'All-in-one platform for trading Forex, Stocks, and Futures',
        '38+ technical indicators',
        '1-click trading and trade from charts',
      ],
    },
    webtrader: {
      text: 'Status is a web-based trading platform. Traders can access it anytime, anywhere and start trading online. Whether you type or swipe, the platform runs smoothly on all browsers and through all operating systems.',
      image: webtrader,
      bullets: [
        'No download required',
        'Real-time market updates and economic events',
        'Intuitive user interface',
      ],
    },
  };

  const handlePlatformChange = (event, newPlatform) => {
    setPlatform(newPlatform);
  };

  return (
    <div className="tabs">
      <div className="tabs-inner container">
        <div className="title-box">
          <h2 className="title">Technology</h2>
          <p className="subtitle">
            Every good trader needs trading software to help them, monitor,
            analyze and execute trades. At Axiance, we make no compromises and
            offer our users the best the market has to offer.
          </p>
        </div>
        <div className="tabs-box">
          <Tabs
            value={platform}
            onChange={handlePlatformChange}
            aria-label="styled tabs example"
            className="tabs-selector"
          >
            <Tab label="Meta Trader 4" value="mt4" className="tab" />
            <Tab label="Meta Trader 5" value="mt5" className="tab" />
            <Tab label="Web Trader" value="webtrader" className="tab" />
          </Tabs>
          <div className="platform-box">
            <img
              src={platformData[platform].image}
              className="graphic-image"
              height="300"
              alt="Mobile Infographic"
            />
            <div className="text-box">
              <p className="text">{platformData[platform].text}</p>
              <ul className="bullets">
                {platformData[platform].bullets.map((bullet) => (
                  <li key={bullet} className="bullet">{bullet}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
