import React from 'react';
import HeadShake from 'react-reveal/HeadShake';
import { useTranslation } from 'react-i18next';
import RegistrationLinks from '../utils/RegistrationLinks';
import '../styles/components/PopOut.scss';
import Button from './Button';

export default function PopOut({
  children, cssClass, entity,
}) {
  const { t } = useTranslation();

  return (
    <div className={`popout ${cssClass}`}>
      <HeadShake>
        <div className="popout-inner container">
          <div className="text-box">
            {children}
          </div>
          <div className="cta-box">
            <Button link={entity === 'fsa' ? RegistrationLinks.intLive : RegistrationLinks.euLive} secondaryColor riskWarning text={t('cta.live')} arrow />
            <Button link={entity === 'fsa' ? RegistrationLinks.intDemo : RegistrationLinks.euDemo} text={t('cta.demo')} arrow />
          </div>
        </div>
      </HeadShake>
    </div>
  );
}
