import React from 'react';
import {
  Header,
  Footer,
  Overlay,
  SplitSectionEmbed,
  TabsBlock,
  InfoIcons,
} from '../components';
import MobileGraphic from '../assets/images/graphics/mobile.png';
import BackgroundImage from '../assets/images/backgrounds/coming-soon-header-bg.png';

function ComingSoon({ entity }) {
  return (
    <div className="App">
      <Header cssClass="header" emailCatcher image={MobileGraphic} background={BackgroundImage}>
        <h1 className="title">Where values matter.</h1>
        <h2 className="subtitle">
          The new online brokerage brand with ambitions of change and
          innovation.
        </h2>
      </Header>
      <SplitSectionEmbed
        title="A modern-day broker for a new generation of traders"
        text={(
          <>
            We are a multi-asset brokerage in the making; the brand dedicated to
            ethical trading practices, and currently gearing up towards offering
            CFD investment services as the first stage of our long-term product
            vision.
            <br />
            <br />
            <strong>Our mission:</strong>
            {' '}
            setting the standard in responsible
            investing for retail and institutional clients.
            <br />
            <br />
            As a broker with sustainability and CSR at its heart and soul, we
            aim to stand out by providing financial instruments that hit metrics
            which matter to forward-thinking investors and reflect their values
            in a smart, cost efficient and fully transparent and fair trading
            environment.
          </>
        )}
        videoUrl="Ih5-wwgBQGc"
      />
      <Overlay />
      <TabsBlock />
      <SplitSectionEmbed
        title="Trading Education With Axiance"
        text={(
          <>
            Axiance values personal growth. This is why it offers a
            comprehensive training infrastructure and seasoned experts to help
            build stronger knowledge of the markets, with resources that range
            from engaging webinars to market analysis.
          </>
        )}
        bullets={[
          'Training Videos',
          'Trading Central',
          'Trading TV',
          'Webinars',
          'Glossary',
        ]}
        videoUrl="B8TRRGz0fSk"
      />
      <InfoIcons />
      <Footer entity={entity} />
    </div>
  );
}

export default ComingSoon;
