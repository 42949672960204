import React from 'react';
import HeadShake from 'react-reveal/HeadShake';
import { useTranslation } from 'react-i18next';
import '../styles/components/StepsBlock.scss';
import Button from './Button';

export default function StepsBlock({
  children, cssClass, title, cta, link,
}) {
  const { t } = useTranslation();

  return (
    <div className={`steps-block ${cssClass}`}>
      <HeadShake>
        <div className="steps-block-inner container">
          <h1 className="title">{title}</h1>
          <div className="stepper">
            {children}
          </div>
          {cta
            && (
            <div className="cta-box">
              <Button
                secondaryColor
                riskWarning
                link={link}
                text={t('cta.start')}
                arrow
              />
            </div>
            )}
        </div>
      </HeadShake>
    </div>
  );
}
