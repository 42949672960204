import React from 'react';
import '../styles/components/Overlay.scss';
import Products from '../assets/images/graphics/products.png';

export default function Overlay() {
  return (
    <div className="overlay">
      <div className="overlay-inner container">
        <div className="green-box">
          <div className="graphic-box">
            <img
              src={Products}
              className="graphic-image"
              width="400"
              height="380"
              alt="Mobile Infographic"
            />
          </div>
          <div className="text-box">
            <h2 className="title">Products</h2>
            <p className="text">
              Axiance provides a diversified range of Contracts for Difference
              (CFDs), a product that gives investors access to speculate on
              short-term price movement of assets without physically trading or
              owning the asset. The diversified range of investment products
              from global markets covers
              {' '}
              <strong>300+ instruments</strong>
              {' '}
              through high-performance MT4, MT5 and Status Web Trader platforms.
              <br />
              <br />
              CFDs on Axiance are available on 50+ major, minor and exotic Forex
              pairs, 200+ stocks of global companies (Amazon, Apple, Tesla)
              commodities (Brent, spot gold and more), top global
              cryptocurrencies (BTC, Ethereum, Litecoin and more) and indexes
              (FTSE 100, SPX 500 and more).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
