import React from 'react';
import '../styles/components/SplitSectionEmbed.scss';

export default function SplitSectionEmbed({
  title, text, bullets, videoUrl,
}) {
  return (
    <div className="split-section">
      <div className="split-section-inner container">
        <div className="text-box">
          <h2 className="title">{title}</h2>
          <p className="text">{text}</p>
          {bullets ? (
            <ul className="bullets">
              {bullets.map((bullet) => (
                <li key={bullet} className="bullet">{bullet}</li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="video-box">
          <iframe
            className="video-embed"
            width="550"
            height="310"
            src={`https://www.youtube.com/embed/${videoUrl}?controls=0`}
            title="Axiance Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}
