import React, { useState } from 'react';
import { useForm } from '@formcarry/react';
import Slide from 'react-reveal/Slide';
import { useMediaQuery } from 'react-responsive';
import Button from './Button';
import ThankYouPopup from './ThankYouPopup';
import '../styles/components/Header.scss';
import Logo from '../assets/images/logos/light-logo.png';
import ScrollDown from './ScrollDown';

export default function Header({
  cssClass, children, emailCatcher, image, background, mobileBackground, scroller, animate,
}) {
  let headerStyles;
  const [thankYou, showThankYou] = useState(false);
  const [headerLoaded, setHeaderLoaded] = useState(false);
  const { submit } = useForm({
    id: 'E8WjmdZP2q_',
  });

  const submitForm = (event) => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-405102058/klU4CLjE5dwCEOq7lcEB',
    });
    submit(event);
    showThankYou(true);
  };

  if (mobileBackground && useMediaQuery({ query: '(max-width: 576px)' })) {
    headerStyles = {
      background: `url(${mobileBackground})`,
    };
  } else {
    headerStyles = {
      background: `url(${background})`,
    };
  }

  return (
    <div className={`${cssClass} ${headerLoaded ? null : 'headerLoading'}`} style={headerStyles} onLoad={() => setTimeout(() => setHeaderLoaded(true), 250)}>
      <ThankYouPopup
        title="Thank You!"
        text="Thank you for submitting your email, we will keep in touch"
        showPopup={thankYou}
        onClosePopup={() => showThankYou(false)}
      />
      <header className="header-inner container">
        <div className="logo-box">
          <img src={Logo} className="logo" alt="logo" />
        </div>
        <div className="content-box">
          <div className="text-box">
            {children}
            {emailCatcher
              ? (
                <form
                  onSubmit={submitForm}
                  acceptCharset="UTF-8"
                  className="newsletter-form"
                >
                  <h3 className="sub-text green-text">Coming Soon</h3>
                  <input
                    className="email"
                    placeholder="Email Address"
                    type="email"
                    required
                    id="email"
                    name="email"
                  />
                  <div className="checkbox-box">
                    <input
                      type="checkbox"
                      id="newsletter"
                      name="Newsletter"
                      value="Agreed"
                    />
                    <label className="checkbox" htmlFor="newsletter">
                      Keep me posted about Axiance news and promotions
                    </label>
                  </div>
                  <Button secondaryColor formButton text="Subscribe" arrow />
                </form>
              )
              : null }
          </div>
          {animate ? (
            <Slide right>
              <div className="graphic">
                <img
                  src={image}
                  className="graphic-image"
                  height="550"
                  alt="Header Infographic"
                />
              </div>
            </Slide>
          ) : (
            <div className="graphic">
              <img
                src={image}
                className="graphic-image"
                height="550"
                alt="Header Infographic"
              />
            </div>
          )}
        </div>
      </header>
      {scroller && <ScrollDown /> }
    </div>
  );
}
