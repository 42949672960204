import React from 'react';
import '../styles/components/TrustPilot.scss';

export default function TrustPilot({ cssClass }) {
  return (
    <div className={`trust-pilot ${cssClass}`}>
      <div className="trust-pilot-inner container">
        <div
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="60b49d1fe480e900015d4586"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="dark"
          data-stars="4,5"
          data-review-languages="en"
        >
          <a
            href="https://uk.trustpilot.com/review/axiance.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  );
}
