const intLive = 'https://client.axiance.com/int/sign-up/live?utm_source=live-account-registration&utm_medium=Lp&utm_campaign=07-10-2021&utm_id=trade-with-speed-performace-and-trust';

const intDemo = 'https://client.axiance.com/int/sign-up/demo?utm_source=demo-account-registration&utm_medium=Lp&utm_campaign=07-10-2021&utm_id=trade-with-speed-performace-and-trust';

const euLive = 'https://client.axianceeu.com/sign-up/live?utm_source=live-account-registration&utm_medium=Lp-eu&utm_campaign=07-10-2021&utm_id=trade-with-speed-performace-and-trust';

const euDemo = 'https://client.axianceeu.com/sign-up/demo?utm_source=demo-account-registration&utm_medium=Lp-eu&utm_campaign=07-10-2021&utm_id=trade-with-speed-performace-and-trust';

const intAffise = 'https://axiance.g2afse.com/click?pid=58&offer_id=3&sub1=0_swaps_lp&sub2=cta';

export default {
  intLive, intDemo, euLive, euDemo, intAffise,
};
