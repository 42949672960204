import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import {
  ComingSoonPage,
  TradeSpeedPerformancePage,
  SwapFees,
  DirectoryPage,
} from './pages';

function App() {
  const entity = window.location.hostname === 'eu.axiance.com' ? 'cysec' : 'fsa';

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <DirectoryPage entity={entity} />
        </Route>
        <Route path="/coming-soon">
          <ComingSoonPage entity={entity} />
        </Route>
        <Route path="/:language?/trade-with-speed-performance-and-trust">
          <TradeSpeedPerformancePage entity={entity} />
        </Route>
        <Route path="/0-swap-fees-with-axiance">
          <SwapFees entity={entity} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
