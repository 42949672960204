import React from 'react';
import '../styles/components/Step.scss';

export default function Step({
  icon, digit, title, text, splitter,
}) {
  return (
    <>
      <div className="step">
        <img alt={title} src={icon} className="icon" />
        <span className="digit">{digit}</span>
        <h3 className="subtitle">{title}</h3>
        <p className="text">{text}</p>
      </div>
      {splitter
        && <hr className="dotted-splitter" />}
    </>
  );
}
