import React from 'react';
import '../styles/components/InfoIcons.scss';
import Personally from '../assets/images/icons/personally.png';
import Sustainable from '../assets/images/icons/sustainable.png';
import Ambassador from '../assets/images/icons/ambassador.png';
import FootPrint from '../assets/images/icons/footprint.png';
import Investors from '../assets/images/icons/investors.png';
import Antri from '../assets/images/graphics/antri.png';

export default function InfoIcons() {
  return (
    <div className="info-icons">
      <div className="info-icons-inner container">
        <div className="title-box">
          <h2 className="title">Our philosophy</h2>
          <p className="subtitle">
            Our philosophy is underpinned by our
            {' '}
            <strong>socially responsible operations</strong>
            {' '}
            and our
            {' '}
            <strong>ESG compliance</strong>
            {' '}
            which create value and
            responsibility towards our staff and integrity as a business towards
            our clients.
          </p>
        </div>
        <div className="icon-box">
          <div className="icon-row">
            <div className="icon">
              <div className="title-icon-box">
                <img
                  src={Personally}
                  className="graphic-icon"
                  height="50"
                  alt="Ambassador Infographic"
                />
                <h2 className="title">We take it personally</h2>
              </div>
              <p className="text">
                It was time for a new player in the online trading industry to
                take on more responsibilities and go beyond compliance, bringing
                real value to traders with a philosophy that encompassed the
                financial world and the environment.
                <br />
                <br />
                Growing in tune with the times requires a fresh mindset and new
                perspectives on what builds strong strategies. Axiance is the
                result of a fundamentally new model where small steps towards
                sustainability and large business transformations are equally
                valued.
              </p>
            </div>
            <div className="icon">
              <div className="title-icon-box">
                <img
                  src={Sustainable}
                  className="graphic-icon"
                  height="50"
                  alt="Sustainable Infographic"
                />
                <h2 className="title">Sustainable habits</h2>
              </div>
              <p className="text">
                Because creating value for
                {' '}
                <strong>society</strong>
                {' '}
                and the
                {' '}
                <strong>environment</strong>
                {' '}
                is also our
                <strong>goal</strong>
                ,
                we believe small incremental changes are crucial to making a
                bigger difference on the long run.
                <br />
                <br />
                We implement recycling in all our activities and our
                {' '}
                <strong>people</strong>
                {' '}
                are
                <strong>trained</strong>
                {' '}
                to adopt
                and encourage daily
                <strong>sustainable</strong>
                {' '}
                habits.
                Business travel is reduced to the bare minimum.
              </p>
            </div>
          </div>
          <div className="icon-row">
            <div className="icon">
              <div className="title-icon-box">
                <img
                  src={FootPrint}
                  className="graphic-icon"
                  height="50"
                  alt="FootPrint Infographic"
                />
                <h2 className="title">A greener footprint</h2>
              </div>
              <p className="text">
                Being headquartered in Cyprus, we enjoy 360 days of sunshine or,
                in other words, unlimited renewable solar energy. And because
                buildings account for 40% of global CO2 emissions, we have
                equipped ours with a 50 kW solar panel system to reduce our
                emissions.
                <br />
                <br />
                In partnership with UK-based social enterprise Ecologi, our
                {' '}
                <strong>Climate Positive Workforce</strong>
                {' '}
                initiative has
                helped
                <strong>reforestation</strong>
                {' '}
                across the world with
                {' '}
                <strong>4000+ trees</strong>
                {' '}
                planted and offset
                {' '}
                <strong>300+ tons</strong>
                {' '}
                of CO2 emissions so far. Axiance is
                also involved in various CSR activities throughout the year to
                foster solidarity in the local communities.
              </p>
            </div>
            <div className="icon">
              <div className="title-icon-box">
                <img
                  src={Investors}
                  className="graphic-icon"
                  height="50"
                  alt="Investors Infographic"
                />
                <h2 className="title">Investors in People</h2>
              </div>
              <p className="text">
                Investing in our
                {' '}
                <strong>people</strong>
                {' '}
                is essential in
                achieving
                {' '}
                <strong>continuous growth</strong>
                {' '}
                and delivering
                {' '}
                <strong>sustainable results</strong>
                . We are a people-focused
                organisation and this is what will drive and sustain our
                competitive advantage.
                <br />
                <br />
                As part of our continued efforts to become a
                {' '}
                <strong>standard-setting organisation</strong>
                {' '}
                in our field, we
                are implementing the
                <strong>Investors In People (IIP)</strong>
                {' '}
                accreditation requirements with the goal to successfully
                complete the process and bear one the most recognized marks of
                good practice.
              </p>
            </div>
          </div>
          <div className="icon-row">
            <div className="icon">
              <img
                src={Antri}
                className="graphic-image"
                height="250"
                alt="Antri Christoforou"
              />
            </div>
            <div className="icon">
              <div className="title-icon-box">
                <img
                  src={Ambassador}
                  className="graphic-icon"
                  height="50"
                  alt="Ambassador Infographic"
                />
                <h2 className="title">Brand ambassador</h2>
              </div>
              <p className="text">
                We are the proud sponsors of
                {' '}
                <strong>Olympian</strong>
                {' '}
                pro-cyclist
                {' '}
                <strong>Antri Christoforou</strong>
                {' '}
                of the Burgos
                Alimenta team.
                <br />
                <br />
                Antri Christoforou is a Cypriot professional cyclist with many
                accolades and experience at the highest levels of competition
                since 2014, with participation in the
                {' '}
                <strong>2016 Olympics in Rio</strong>
                {' '}
                and
                {' '}
                <strong>2021 in Tokyo</strong>
                .
                <br />
                <br />
                Through the green and noble sport of road cycling, Antri
                reflects values Axiance embraces and promotes; integrity,
                discipline, ecology and performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
