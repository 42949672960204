import React from 'react';
import '../styles/components/ThankYouPopup.scss';
import CancelIcon from '@material-ui/icons/Cancel';
import ThankYouIcon from '../assets/images/icons/thank-you.png';
import Button from './Button';

export default function ThankYouPopup({
  showPopup,
  title,
  text,
  onClosePopup,
}) {
  return (
    <div className={showPopup ? 'thank-you' : 'thank-you hide-thank-you'}>
      <div className="thank-you-inner container">
        <CancelIcon className="close-icon secondary-color" onClick={onClosePopup} />
        <div className="popup">
          <img
            src={ThankYouIcon}
            className="icon"
            height="150"
            alt="Thank you icon"
          />
          <h2 className="title">{title}</h2>
          <p className="text">{text}</p>
          <div className="cta-box">
            <Button action={onClosePopup} secondaryColor text="Okay" />
          </div>
        </div>
      </div>
    </div>
  );
}
