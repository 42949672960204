import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import Swing from 'react-reveal/Swing';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Footer,
  SplitSectionImage,
  Button,
  PopOut,
  TrustPilot,
  LogoBar,
} from '../components';
import MobileGraphic from '../assets/images/graphics/trade-mobile.png';
import InvestGraphic from '../assets/images/graphics/invest.png';
import WebinarsGraphic from '../assets/images/graphics/webinars.png';
import TradePlatformsGraphic from '../assets/images/graphics/trade-platforms.png';
import BackgroundImage from '../assets/images/backgrounds/trade-speed-header-bg.jpg';
import RegistrationLinks from '../utils/RegistrationLinks';
import '../styles/pages/TradeSpeed.scss';

function TradeSpeedPerformance({ entity }) {
  const { i18n, t } = useTranslation();
  const { language } = useParams();

  useEffect(() => {
    if (language) i18n.changeLanguage(language);
    const script = document.createElement('script');
    script.src = 'https://axianceteam.slack.com//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="trade-with-speed">
      <Header
        cssClass="header"
        image={MobileGraphic}
        background={BackgroundImage}
        animate
      >
        <h1 className="title">
          {t('speedPerformance.header.title')} <span className="secondary-color line-breaker">{t('speedPerformance.header.greenTitle')}</span>
        </h1>
        <h2 className="subtitle">
          {t('speedPerformance.header.textLines', { returnObjects: true }).map((line) => (
            <>{line}<br /><br /></>
          ))}
        </h2>
        <div className="cta-box">
          <Button
            secondaryColor
            riskWarning
            link={entity === 'fsa' ? RegistrationLinks.intLive : RegistrationLinks.euLive}
            text={t('cta.live')}
            arrow
          />
          <Button link={entity === 'fsa' ? RegistrationLinks.intDemo : RegistrationLinks.euDemo} text={t('cta.demo')} arrow />
        </div>
      </Header>
      <SplitSectionImage
        cssClass="invest"
        reversed
        image={{ src: InvestGraphic, alt: 'Invest' }}
      >
        <h1 className="title">{t('speedPerformance.invest.title')}</h1>
        <p className="text" dangerouslySetInnerHTML={{ __html: t('speedPerformance.invest.text', { interpolation: { escapeValue: false } }) }} />
      </SplitSectionImage>
      <SplitSectionImage
        cssClass="trading-platforms"
        image={{ src: TradePlatformsGraphic, alt: 'Trading Platforms' }}
      >
        <h1 className="title">{t('speedPerformance.power.title')}</h1>
        <p className="text primary-color">{t('speedPerformance.power.greenSub')}</p>
        <p className="text">{t('speedPerformance.power.text')}</p>
        {t('speedPerformance.power.platforms', { returnObjects: true }).map((platform) => (
          <>
            <h2 className="subtitle">{platform.title}</h2>
            <p className="text">{platform.text}</p>
            <Swing>
              <a className="link" href={entity === 'fsa' ? RegistrationLinks.intLive : RegistrationLinks.euLive}>
                {platform.link}<BsArrowRight className="link-arrow" />
              </a>
            </Swing>
          </>
        ))}
      </SplitSectionImage>
      <SplitSectionImage
        cssClass="webinars"
        reversed
        image={{ src: WebinarsGraphic, alt: 'Webinars' }}
      >
        <h1 className="title">{t('speedPerformance.leaps.title')}</h1>
        <p className="text" dangerouslySetInnerHTML={{ __html: t('speedPerformance.leaps.text', { interpolation: { escapeValue: false } }) }} />
        <p className="text">{t('speedPerformance.leaps.well')}</p>
        <Button link={entity === 'fsa' ? RegistrationLinks.intLive : RegistrationLinks.euLive} secondaryColor riskWarning text={t('cta.live')} arrow />
      </SplitSectionImage>
      <PopOut cssClass="trade-pop" entity={entity}>
        <h1 className="title">{t('speedPerformance.popout.title')}</h1>
        <p className="text">{t('speedPerformance.popout.text')}</p>
      </PopOut>
      <TrustPilot />
      <LogoBar />
      <Footer entity={entity} />
    </div>
  );
}

export default TradeSpeedPerformance;
