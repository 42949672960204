import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import {
  Header,
  Footer,
} from '../components';
import DirectoryGraphic from '../assets/images/graphics/directory.png';
import BackgroundImage from '../assets/images/backgrounds/coming-soon-header-bg.png';
import '../styles/pages/Directory.scss';

function ComingSoon({ entity }) {
  return (
    <div className="directory">
      <Header cssClass="header" image={DirectoryGraphic} background={BackgroundImage}>
        <h1 className="title">Axiance Landing Page Directory.</h1>
        <h2 className="subtitle">
          Bellow you can find a list of all landing pages.
        </h2>
      </Header>
      <div className="links-block container">
        <a className="link" href="/coming-soon">
          <BsArrowRight className="link-arrow" />Coming Soon
        </a>
        <a className="link" href="/trade-with-speed-performance-and-trust">
          <BsArrowRight className="link-arrow" />Trade with Speed Performance and Trust
        </a>
        <a className="link" href="/0-swap-fees-with-axiance">
          <BsArrowRight className="link-arrow" />0 Swap Fees with Axiance
        </a>
      </div>
      <Footer entity={entity} />
    </div>
  );
}

export default ComingSoon;
